import { USER_ROLES } from '@/core/enums'

const platformsRouteNames = {
  platformsRoot: 'platformsRoot',
  platforms: 'platforms',
  editPlatforms: 'editPlatforms'
}

const platformsRoutes = {
  name: platformsRouteNames.platformsRoot,
  path: '/platforms',
  redirect: { name: platformsRouteNames.platforms },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: platformsRouteNames.platforms,
      path: '',
      meta: {
        title: 'Platforms',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          label: 'Platforms'
        }]
      },
      component: () => import('@/views/platforms/Platforms.vue')
    },

    {
      name: platformsRouteNames.editPlatforms,
      path: 'edit',
      meta: {
        title: 'Edit platforms',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          routeName: platformsRouteNames.platforms,
          label: 'Platforms'
        }, {
          label: 'Edit platforms'
        }]
      },
      component: () => import('@/views/platforms/EditPlatforms.vue')
    }
  ]
}

export {
  platformsRouteNames,
  platformsRoutes
}
