const privacyPolicyRouteNames = {
  privacyPolicyRoot: 'privacyPolicyRoot',
  privacy: 'privacy',
  terms: 'terms'
}

const privacyPolicyRoutes = {
  name: privacyPolicyRouteNames.privacyPolicyRoot,
  path: '/legal',
  redirect: { name: privacyPolicyRouteNames.privacy },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: privacyPolicyRouteNames.privacy,
      path: 'privacy-policy',
      meta: {
        title: 'Privacy policy'
      },
      component: () => import('@/views/privacy-policy/PrivacyPolicy.vue')
    },
    {
      name: privacyPolicyRouteNames.terms,
      path: 'terms',
      meta: {
        title: 'Terms Of Use'
      },
      component: () => import('@/views/privacy-policy/TermsOfUse.vue')
    }
  ]
}

export {
  privacyPolicyRouteNames,
  privacyPolicyRoutes
}
