import { useCookies } from '@vueuse/integrations/useCookies'
import { useJwt } from '@vueuse/integrations/useJwt'

import { authService } from '@/services'

const { get, set, remove } = useCookies()

const tokenName = process.env.VUE_APP_TOKEN_NAME

export const authStore = {
  namespaced: true,

  state: {
    token: get(tokenName),
    user: null
  },

  getters: {
    userFullName (state) {
      return `${state.user?.firstName} ${state.user?.lastName}`
    }
  },

  mutations: {
    setToken (state, token) {
      state.token = token

      const { payload } = useJwt(token)
      const expiresTimestamp = payload.value.exp * 1000
      set(
        tokenName,
        token,
        {
          path: '/',
          secure: true,
          expires: new Date(expiresTimestamp)
        }
      )
    },

    removeToken (state) {
      state.token = null
      remove(tokenName)
    },

    setUser (state, user) {
      state.user = user
    }
  },

  actions: {
    async login ({ commit, dispatch }, loginForm) {
      const { token } = await authService.login(loginForm)

      commit('setToken', token)

      await dispatch('getUser')

      return token
    },

    async refreshToken ({ commit }) {
      const { token } = await authService.refreshToken()

      commit('setToken', token)

      return token
    },

    logout ({ commit }) {
      commit('removeToken')
      window.location.href = '/auth/login'
    },

    async getUser ({ commit }) {
      const user = await authService.getUser()

      commit('setUser', user)

      return user
    }
  }
}
