<template>
  <div class="w-full flex flex-1 overflow-y-auto">
    <AppSidebar v-model="isSidebarExpanded" />

    <div
      class="w-full flex flex-col overflow-y-auto"
      :class="{ 'truncate': isBreadcrumbs }"
    >
      <AppHeader
        @onSidebarOpen="isSidebarExpanded = true"
      />

      <div
        class="flex flex-1 rounded-0 md:rounded-tl-10 bg-gray-100 overflow-y-auto pt-12 md:pt-24 md:pl-24 pl-0"
      >
        <div
          class="flex flex-1 rounded-0 bg-white overflow-y-auto md:rounded-tl-10 md:pt-16 md:px-24 pt-12 px-12"
        >
          <router-view #default="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import AppSidebar from '@/components/stateless/layout/AppSidebar.vue'
import AppHeader from '@/components/stateless/layout/AppHeader.vue'

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    AppSidebar,
    AppHeader
  },

  setup () {
    const route = useRoute()

    const isMobile = window.innerWidth >= 768
    const isSidebarExpanded = ref(isMobile)

    const isBreadcrumbs = computed(() => {
      return route.meta?.breadcrumbs
    })

    return {
      isSidebarExpanded,
      isBreadcrumbs
    }
  }
})
</script>
