export const API_ERRORS = {
  INVALID_USER_ROLE: 100,
  INVALID_EMAIL_OR_PASSWORD: 101,
  PASSWORDS_NOT_MATCHED: 102,
  INVALID_PHONE: 103,
  INVALID_FIRST_NAME: 104,
  INVALID_LAST_NAME: 105,
  INVALID_EMAIL: 106,
  INVALID_PASSWORD: 107,
  INVALID_USER_ID: 108,

  EMAIL_USED: 200,
  USER_NOT_FOUND: 201,
  EMAIL_DOES_NOT_EXIST: 203,

  INVALID_SIGNATURE: 205,
  INVALID_EXPIRATION: 206,

  NOT_AUTHENTICATED: 207,
  NOT_PERMITTED: 208,
  INVALID_PAYLOAD: 209,
  INVALID_FILE_FORMAT: 210,

  INVALID_RECOURSE_NAME: 300,
  INVALID_RECOURSE_DOMAIN: 301,
  INVALID_RECOURSE_TITLE_FIELD: 302,
  INVALID_RECOURSE_THUMBNAIL_URL_FIELD: 303,
  INVALID_RECOURSE_CONTENT_FIELD: 304,
  INVALID_RECOURSE_GUID_FIELD: 305,
  INVALID_RECOURSE_RSS_URL: 306,
  RECOURSE_ALREADY_EXIST: 307,
  INVALID_RECOURSE_ID: 308,
  RECOURSE_NOT_FOUND: 309,
  INVALID_RECOURSE_LAST_POST_GUID: 310,

  POST_ALREADY_EXIST: 400,
  INVALID_POST_ID: 401,
  POST_NOT_FOUND: 402,
  INVALID_POST_TITLE: 403,
  INVALID_POST_THUMBNAIL_URL: 404,
  INVALID_POST_CONTENT: 405,
  INVALID_TELEGRAM_CONTENT: 406,
  INVALID_TWITTER_CONTENT: 407,
  INVALID_INSTAGRAM_CONTENT: 408,
  POST_ALREADY_PUBLISHED: 409,

  SIGNATURE_NOT_FOUND: 500,
  INVALID_SIGNATURE_PLATFORM: 501,
  INVALID_IS_ACTIVE: 502,

  INVALID_PLATFORM: 600,
  PLATFORM_NOT_FOUND: 601,
  INSTAGRAM_PUBLISH_ERROR: 602
}
