<template>
  <div
    v-if="modelValue"
    class="block md:hidden fixed inset-0 z-10 opacity-30 bg-blueGray-900 cursor-pointer"
    @click="onExpandHandler(false)"
  />

  <aside
    class="
      flex flex-1 flex-col bg-white md:pb-40 md:pt-20 py-12 md:relative
      fixed top-0 bottom-0 z-20 shadow-lg md:shadow-none
    "
    :class="{
      'min-w-230 w-230 md:min-w-280 md:w-280 left-0': modelValue,
      'min-w-230 w-230 md:min-w-64 md:w-64 -left-full': !modelValue
    }"
  >
    <!-- Close button -->
    <div
      class="md:w-40 md:h-40 w-32 h-32 flex justify-center items-center transition duration-200
      bg-blue-600 rounded-full cursor-pointer absolute right-0 md:bottom-0 md:top-auto
      hover:bg-blue-700 -mr-12 md:mb-8"
      @click="onExpandHandler(!modelValue)"
    >
      <i
        class="icon-arrow-left2 text-white md:text-xl text-base transition duration-200"
        :class="{'transform rotate-180': !modelValue}"
      />
    </div>

    <div class="w-full h-full flex flex-1 flex-col overflow-y-auto">
      <!-- Logo -->
      <div class="w-full px-12 md:px-20 md:h-40 h-32 font-bold">
        <template v-if="modelValue">Crypto Ninja</template>
        <template v-else>CN</template>
      </div>
      <!-- Logo -->

      <!-- Navigation -->
      <div class="flex flex-col justify-between flex-1 mt-24 overflow-y-auto">
        <div class="w-full">
          <template
            v-for="(link, index) in navigationLinks"
            :key="index"
          >
            <AppSidebarItem
              v-if="link.routeName"
              :icon="link.icon"
              component="router-link"
              :to="link.routeName"
              :show-label="modelValue"
            >
              <template #label>{{ link.title }}</template>
            </AppSidebarItem>

            <div
              v-else
              class="w-full h-1 my-16 md:my-24 px-20"
            >
              <div class="w-full h-full bg-gray-200 mx-auto" />
            </div>
          </template>
        </div>

        <div class="w-full">
          <AppSidebarItem
            icon="icon-exit"
            :show-label="modelValue"
            @click="logoutHandler"
          >
            <template #label>Logout</template>
          </AppSidebarItem>
        </div>
      </div>
    <!-- Navigation -->
    </div>
  </aside>
</template>

<script>
import { defineComponent, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { routeNames } from '@/router'
import { store } from '@/store'

import AppSidebarItem from '@/components/stateless/layout/components/AppSidebarItem.vue'

export default defineComponent({
  name: 'AppSidebar',

  components: {
    AppSidebarItem
  },

  props: {
    modelValue: {
      type: Boolean,
      default: true,
      required: true
    }
  },

  emits: ['update:modelValue'],

  setup (_, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const navigationLinks = [{
      title: 'Posts',
      icon: 'icon-home3',
      routeName: 'posts'
    },
    {
      title: 'separator'
    },
    {
      title: 'Platforms',
      icon: 'icon-cog',
      routeName: 'platforms'
    },
    {
      title: 'RSS Feed example',
      icon: 'icon-cog',
      routeName: 'rssResponse'
    },
    {
      title: 'Resources',
      icon: 'icon-cog',
      routeName: 'resources'
    },
    {
      title: 'Social signatures',
      icon: 'icon-cog',
      routeName: 'socialSignatures'
    },
    {
      title: 'separator'
    },
    {
      title: 'Profile',
      icon: 'icon-user-tie',
      routeName: 'profile'
    }]

    watch(() => route.name, (newRoute) => {
      if (window.innerWidth < 768) {
        onExpandHandler(false)
      }
    })

    const onExpandHandler = (flag) => {
      emit('update:modelValue', flag)
    }

    const onLinkClickHandler = (routeName) => {
      router.push({ name: routeName })
    }

    const logoutHandler = () => {
      store.dispatch('authStore/logout')
    }

    return {
      routeNames,
      navigationLinks,
      onExpandHandler,
      logoutHandler,
      onLinkClickHandler
    }
  }
})
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .-left-full {
      left: -100%;
    }
  }
</style>
