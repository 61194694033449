import { http } from '@/services'

class PostsService {
  getList ({ page, size, options }) {
    const pageApi = page - 1

    return http.get('/post', { params: { page: pageApi, size, ...options } })
  }

  getPost (id) {
    return http.get(`/post/${id}`)
  }

  publishPost (id, formData) {
    return http.post(`post/${id}/publish`, formData)
  }

  createAndPublishPost (formData) {
    return http.post('/post/create/publish', formData)
  }

  editPost (id, formData) {
    return http.put(`/post/${id}`, formData)
  }

  deletePost (id) {
    return http.delete(`/post/${id}`)
  }

  generateDailyMarketOverviewPost () {
    return http.get('/post/generate-daily-market-overview/')
  }
}

export const postsService = new PostsService()
