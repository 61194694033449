import { http } from '@/services'

class ResourcesService {
  getList ({ page, size }) {
    const pageApi = page - 1

    return http.get('/resource', { params: { page: pageApi, size } })
  }

  getResource (id) {
    return http.get(`/resource/${id}`)
  }

  createResource (formData) {
    return http.post('/resource', formData)
  }

  editResource (id, formData) {
    return http.put(`/resource/${id}`, formData)
  }

  deleteResource (id) {
    return http.delete(`/resource/${id}`)
  }
}

export const resourcesService = new ResourcesService()
