import { ElLoading } from 'element-plus'

import { store } from '@/store'
import { routeNames } from '@/router'
import { asyncTimeout } from '@/core/helpers'

const getUser = async () => {
  const loading = ElLoading.service({ fullscreen: true })

  try {
    await asyncTimeout()
    await store.dispatch('authStore/getUser')

    // Refresh token - TODO: create better aproach to update token
    await store.dispatch('authStore/refreshToken')
  } catch (e) {
    store.dispatch('authStore/logout')
  } finally {
    loading.close()
  }
}

export const routeGuard = async (to, from, next) => {
  const isRouteRequiredAuth = to.meta.requiresAuth

  if (isRouteRequiredAuth && !store.state.authStore.token) {
    return next({ name: routeNames.login })
  }

  if (!isRouteRequiredAuth && store.state.authStore.token) {
    store.commit('authStore/removeToken')
  }

  if (store.state.authStore.token && !store.state.authStore.user) {
    await getUser()
  }

  // Set title in browser tab
  document.title = to.meta?.title || 'Crypto Ninja'

  next()
}
