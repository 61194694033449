import { http } from '@/services'

class PlatformsService {
  getAll () {
    return http.get('/platforms')
  }

  update (platform, active) {
    return http.put(`/platforms/${platform}`, { active })
  }
}

export const platformsService = new PlatformsService()
