import { USER_ROLES } from '@/core/enums'

const socialSignaturesRouteNames = {
  socialSignaturesRoot: 'socialSignaturesRoot',
  socialSignatures: 'socialSignatures',
  editSocialSignatures: 'editSocialSignatures'
}

const socialSignaturesRoutes = {
  name: socialSignaturesRouteNames.socialSignaturesRoot,
  path: '/social-signatures',
  redirect: { name: socialSignaturesRouteNames.socialSignatures },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: socialSignaturesRouteNames.socialSignatures,
      path: '',
      meta: {
        title: 'Social signatures',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          label: 'Social signatures'
        }]
      },
      component: () => import('@/views/social-signatures/SocialSignatures.vue')
    },

    {
      name: socialSignaturesRouteNames.editSocialSignatures,
      path: 'edit/:platform',
      meta: {
        title: 'Edit social signatures',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          routeName: socialSignaturesRouteNames.socialSignatures,
          label: 'Social signatures'
        }, {
          label: 'Edit social signatures'
        }]
      },
      component: () => import('@/views/social-signatures/EditSocialSignatures.vue')
    }
  ]
}

export {
  socialSignaturesRouteNames,
  socialSignaturesRoutes
}
