import { authRouteNames } from '@/views/auth/auth.routes'
import { platformsRouteNames } from '@/views/platforms/platforms.routes'
import { rssResponseRouteNames } from '@/views/rss-response/rss-response.routes'
import { resourceRouteNames } from '@/views/resources/resources.routes'
import { socialSignaturesRouteNames } from '@/views/social-signatures/social-signatures.routes'
import { postsRouteNames } from '@/views/posts/posts.routes'
import { profileRouteNames } from '@/views/profile/profile.routes'
import { privacyPolicyRouteNames } from '@/views/privacy-policy/privacy-policy.routes'

export const routeNames = {
  login: 'login',
  ...authRouteNames,
  ...platformsRouteNames,
  ...rssResponseRouteNames,
  ...resourceRouteNames,
  ...socialSignaturesRouteNames,
  ...postsRouteNames,
  ...profileRouteNames,
  privacyPolicyRouteNames
}
