import AuthLayout from '@/layouts/AuthLayout.vue'

const authRouteNames = {
  auth: 'auth',
  login: 'login',
  // signup: 'signup',
  forgot: 'forgot',
  reset: 'reset'
}

const authRoutes = {
  name: authRouteNames.auth,
  path: '/auth',
  redirect: { name: authRouteNames.login },
  component: AuthLayout,
  children: [
    {
      name: authRouteNames.login,
      path: 'login',
      meta: {
        title: 'Login'
      },
      component: () => import('@/views/auth/Login.vue')
    },
    // {
    //   name: authRouteNames.signup,
    //   path: 'signup',
    //   meta: {
    //     title: 'Sign up'
    //   },
    //   component: () => import('@/views/auth/Signup.vue')
    // },
    {
      name: authRouteNames.forgot,
      path: 'forgot-password',
      meta: {
        title: 'Forgot password'
      },
      component: () => import('@/views/auth/Forgot.vue')
    },
    {
      name: authRouteNames.reset,
      path: 'reset-password',
      meta: {
        title: 'Reset password'
      },
      component: () => import('@/views/auth/Reset.vue')
    }
  ]
}

export {
  authRouteNames,
  authRoutes
}
