export * from './api-errors'

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user'
}

export const PLATFORMS = {
  TELEGRAM: 'telegram',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram'
}

export const RSS_IMAGE_RESOURCE_TYPE = {
  FROM_CONTENT: 'FROM_CONTENT'
}
