import { http } from '@/services'

class ProfileService {
  changePassword (formData) {
    return http.post('/profile/update-password', formData)
  }

  editProfile (formData) {
    return http.put('/profile/update', formData)
  }
}

export const profileService = new ProfileService()
