const profileRouteNames = {
  profileRoot: 'profileRoot',
  profile: 'profile',
  changePasswordProfile: 'changePasswordProfile'
}

const profileRoutes = {
  name: profileRouteNames.profileRoot,
  path: '/profile',
  redirect: { name: profileRouteNames.profile },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: profileRouteNames.profile,
      path: '',
      meta: {
        title: 'Profile',
        requiresAuth: true,
        breadcrumbs: [{
          label: 'Profile'
        }]
      },
      component: () => import('@/views/profile/Profile.vue')
    },

    {
      name: profileRouteNames.changePasswordProfile,
      path: 'change-password',
      meta: {
        title: 'Change password',
        requiresAuth: true,
        breadcrumbs: [{
          routeName: profileRouteNames.profile,
          label: 'Profile'
        }, {
          label: 'Change password'
        }]
      },
      component: () => import('@/views/profile/ChangePasswordProfile.vue')
    }

    // {
    //   name: profileRouteNames.editPost,
    //   path: 'edit/:id',
    //   meta: {
    //     title: 'Edit resource',
    //     requiresAuth: true,
    //     roles: [
    //       USER_ROLES.ADMIN
    //     ],
    //     breadcrumbs: [{
    //       routeName: profileRouteNames.posts,
    //       label: 'Posts'
    //     }, {
    //       label: 'Edit post'
    //     }]
    //   },
    //   component: () => import('@/views/posts/EditPost.vue')
    // }
  ]
}

export {
  profileRouteNames,
  profileRoutes
}
