<template>
  <header class="w-full flex flex-col p-12 md:px-0 border-b border-gray-200 md:border-none">
    <div class="hidden md:block">
      <div class="w-full flex items-start justify-center flex-col min-h-40">
        <div class="max-w-full flex flex-1 justify-end items-center">
          <div class="flex items-center">
            <i class="icon-home3 text-base leading-none text-blue-600 mr-8" />
            <span class="leading-none">/</span>
          </div>
          <div class="pr-8 truncate direction-rtl">
            <component
              :is="route?.routeName ? 'router-link' : 'span'"
              v-for="(route, index) in pageBreadcrumbs"
              :key="index"
              :to="{ name: route?.routeName }"
              class="text-14 leading-none font-medium text-gray-800"
            >
              <span
                class="px-4"
                :class="{'link': route?.routeName}"
              >
                {{ route.label }}
              </span>
              <span v-if="index < (pageBreadcrumbs.length - 1)" class="text-gray-800">/</span>
            </component>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <div class="block md:hidden">
      <button
        class="w-24 h-16 flex flex-col justify-between cursor-pointer"
        @click="$emit('onSidebarOpen')"
      >
        <div class="w-full h-3 bg-blue-600" />
        <div class="w-full h-3 bg-blue-600" />
        <div class="w-full h-3 bg-blue-600" />
      </button>
    </div>
  </header>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'AppHeader',

  emits: ['onSidebarOpen'],

  setup () {
    const route = useRoute()

    const pageTitle = computed(() => {
      return route.meta?.title || ''
    })

    const pageBreadcrumbs = computed(() => {
      return route.meta?.breadcrumbs || []
    })

    return {
      pageTitle,
      pageBreadcrumbs
    }
  }
})
</script>
