import { USER_ROLES } from '@/core/enums'

const rssResponseRouteNames = {
  rssResponseRoot: 'rssResponseRoot',
  rssResponse: 'rssResponse'
}

const rssResponseRoutes = {
  name: rssResponseRouteNames.rssResponseRoot,
  path: '/rss-response',
  redirect: { name: rssResponseRouteNames.rssResponse },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: rssResponseRouteNames.rssResponse,
      path: '',
      meta: {
        title: 'RSS Feed example',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          label: 'RSS Feed example'
        }]
      },
      component: () => import('@/views/rss-response/RssResponse.vue')
    }
  ]
}

export {
  rssResponseRouteNames,
  rssResponseRoutes
}
