
import { ElLoading } from 'element-plus'

// import lang from 'element-plus/lib/locale/lang/en'
// import locale from 'element-plus/lib/locale'

export const ElementPlugin = {
  install: (app) => {
    app.config.globalProperties.$ELEMENT = { size: 'small' }
    // locale.use(lang)

    // plugins
    app.use(ElLoading)

    // components
  }
}
