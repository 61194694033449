import { http } from '@/services'

class FileUploadService {
  uploadImage (formData) {
    return http.post(
      '/attachments/upload/image',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
}

export const fileUploadService = new FileUploadService()
