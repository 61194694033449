import { http } from '@/services'

class SocialSignaturesService {
  getList ({ page, size }) {
    const pageApi = page - 1

    return http.get('/social-signature', { params: { page: pageApi, size } })
  }

  getSocialSignature (platform) {
    return http.get(`/social-signature/${platform}`)
  }

  editSocialSignature (platform, formData) {
    return http.put(`/social-signature/${platform}`, formData)
  }
}

export const socialSignaturesService = new SocialSignaturesService()
