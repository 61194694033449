import axios from 'axios'

import { store } from '@/store'

const httpService = axios.create({
  // setup root url for all http requests
  baseURL: `${process.env.VUE_APP_API_URL}/api`,

  // customize header
  headers: {
    'Content-Type': 'application/json'
  }
})

httpService.interceptors.request.use((config) => {
  if (store.state.authStore.token) {
    config.headers.Auth = store.state.authStore.token
  }

  return config
})

httpService.interceptors.response.use((response) => {
  if (response?.data) {
    return response.data
  }

  return response
}, (error) => {
  return Promise.reject(error.response.data)
})

export const http = httpService
