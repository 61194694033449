import { http } from '@/services'

class AuthService {
  login (loginForm) {
    return http.post('/auth/login', loginForm)
  }

  // signup (signupForm, role = USER_ROLES.COMPANY_SUPER_ADMIN) {
  //   return http.post(`/auth/register/${role}`, signupForm)
  // }

  recover (recoverForm) {
    return http.post('/auth/request-reset-password', recoverForm)
  }

  reset (resetForm) {
    return http.post('/auth/reset-password', resetForm)
  }

  refreshToken () {
    return http.post('/auth/refresh-token')
  }

  getUser () {
    return http.get('/profile')
  }
}

export const authService = new AuthService()
