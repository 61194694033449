<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :to="{ name: to }"
    class="
      sidebar-link w-full flex items-center group
      transition duration-200 relative
      text-gray-800 hover:text-blue-600 cursor-pointer
    "
    :class="{
      'text-blue-600 active': active,
      'px-12 py-8 text-14 submenu': submenu,
      'px-12 md:px-20 py-8 my-8': !submenu
    }"
  >
    <div
      v-if="icon"
      class="w-20 h-20 md:w-24 md:h-24 flex justify-center items-center"
      :class="{'mr-12 md:mr-16': showLabel}"
    >
      <i
        :class="[icon]"
        class="text-gray-600 text-base md:text-18 transition duration-200 group-hover:text-blue-600"
      />
    </div>

    <span
      v-show="showLabel"
      class="leading-tight break-normal text-left text-14 md:text-base"
      :class="{
        'font-medium': !submenu
      }"
    >
      <slot name="label" />
    </span>
  </component>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppSidebarItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    submenu: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style lang="scss" scoped>
  .sidebar-link {
    &::before {
      content: '';
      position: absolute;
      left: -3px;
      top: 0;
      bottom: 0;
      width: 6px;
      height: 100%;
      border-radius: 4px;
      opacity: 0;
      @apply bg-blue-600 transition duration-200;
    }

    &.submenu {
      &::before {
        display: none;
      }
    }

    &:hover,
    &.active {
      &::before {
        opacity: 1;
      }

      i {
        @apply text-blue-600;
      }
    }
  }
</style>
