import { createApp } from 'vue'
import App from '@/App.vue'

import '@/core/utils'

import router from '@/router'
import { store } from '@/store'
import { ElementPlugin } from './plugins'

import '@/registerServiceWorker'

import '@/assets/styles/main.scss'

export const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(ElementPlugin)

router.isReady().then(() => app.mount('#app'))
