import { ElNotification } from 'element-plus'
import millify from 'millify'

import { API_ERRORS } from '@/core/enums'

export const notification = ({
  title = 'Error',
  type = 'error',
  message = 'Error occurred. Please, try again later.',
  errorCode = null
} = {}) => {
  if (errorCode) {
    message = getApiErrorMessage(errorCode)
  }

  ElNotification({ type, showClose: true, message, title })
}

export const asyncTimeout = async (timer = 1000) => {
  return new Promise(resolve => {
    setTimeout(() => { resolve(true) }, timer)
  })
}

export const getApiErrorMessage = (code) => {
  return Object.keys(API_ERRORS).find(key => API_ERRORS[key] === code)
}

export const booleanValueToLabel = (value) => {
  return value ? 'On' : 'Off'
}

export const converBigNumberToReadLabel = (value) => {
  return millify(value, {
    units: ['', 'k', 'M', 'B', 'T', 'P', 'E'],
    precision: 2
  })
}
