<template>
  <section class="flex flex-1">
    <div class="hidden absolute inset-0 w-1/2 ml-auto bg-yellow-main lg:block">
      <div class="flex items-center h-full">
        <img class="lg:max-w-lg mx-auto" :src="require('@/assets/images/logo-placeholder.jpeg')" alt="Auth">
      </div>
    </div>
    <div class="flex flex-1 container px-16 mx-auto">
      <div class="flex flex-wrap flex-1">
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AuthLayout',

  setup () {
  }
})
</script>
