import { USER_ROLES } from '@/core/enums'

const resourceRouteNames = {
  resourceRoot: 'resourceRoot',
  resources: 'resources',
  addResource: 'addResource',
  editResource: 'editResource'
}

const resourceRoutes = {
  name: resourceRouteNames.resourceRoot,
  path: '/resources',
  redirect: { name: resourceRouteNames.resources },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: resourceRouteNames.resources,
      path: '',
      meta: {
        title: 'Resources',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          label: 'Resources'
        }]
      },
      component: () => import('@/views/resources/Resources.vue')
    },

    {
      name: resourceRouteNames.addResource,
      path: 'add',
      meta: {
        title: 'Add new resource',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          routeName: resourceRouteNames.resources,
          label: 'Resources'
        }, {
          label: 'Add new resource'
        }]
      },
      component: () => import('@/views/resources/AddResource.vue')
    },

    {
      name: resourceRouteNames.editResource,
      path: 'edit/:id',
      meta: {
        title: 'Edit resource',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          routeName: resourceRouteNames.resources,
          label: 'Resources'
        }, {
          label: 'Edit resource'
        }]
      },
      component: () => import('@/views/resources/EditResource.vue')
    }
  ]
}

export {
  resourceRouteNames,
  resourceRoutes
}
