import { USER_ROLES } from '@/core/enums'

const postsRouteNames = {
  postsRoot: 'postsRoot',
  posts: 'posts',
  addPost: 'addPost',
  editPost: 'editPost'
}

const postsRoutes = {
  name: postsRouteNames.postsRoot,
  path: '/posts',
  redirect: { name: postsRouteNames.posts },
  component: () => import('@/layouts/BlankLayout.vue'),
  children: [
    {
      name: postsRouteNames.posts,
      path: '',
      meta: {
        title: 'Posts',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          label: 'Posts'
        }]
      },
      component: () => import('@/views/posts/Posts.vue')
    },

    {
      name: postsRouteNames.addPost,
      path: 'add',
      meta: {
        title: 'Add new post',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          routeName: postsRouteNames.posts,
          label: 'Posts'
        }, {
          label: 'Add new post'
        }]
      },
      component: () => import('@/views/posts/AddPost.vue')
    },

    {
      name: postsRouteNames.editPost,
      path: 'edit/:id',
      meta: {
        title: 'Edit resource',
        requiresAuth: true,
        roles: [
          USER_ROLES.ADMIN
        ],
        breadcrumbs: [{
          routeName: postsRouteNames.posts,
          label: 'Posts'
        }, {
          label: 'Edit post'
        }]
      },
      component: () => import('@/views/posts/EditPost.vue')
    }
  ]
}

export {
  postsRouteNames,
  postsRoutes
}
