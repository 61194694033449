import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'

import { authRoutes } from '@/views/auth/auth.routes'
import { postsRoutes, postsRouteNames } from '@/views/posts/posts.routes'
import { platformsRoutes } from '@/views/platforms/platforms.routes'
import { rssResponseRoutes } from '@/views/rss-response/rss-response.routes'
import { resourceRoutes } from '@/views/resources/resources.routes'
import { socialSignaturesRoutes } from '@/views/social-signatures/social-signatures.routes'
import { profileRoutes } from '@/views/profile/profile.routes'
import { privacyPolicyRoutes } from '@/views/privacy-policy/privacy-policy.routes'

import { routeGuard } from './route-guard'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },

  authRoutes,
  privacyPolicyRoutes,

  {
    path: '/',
    redirect: { name: postsRouteNames.posts },
    component: DefaultLayout,
    meta: { requiresAuth: true },
    children: [
      platformsRoutes,
      rssResponseRoutes,
      resourceRoutes,
      socialSignaturesRoutes,
      postsRoutes,
      profileRoutes
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active text-blue-600'
})

router.beforeEach(routeGuard)

export { routeNames } from './route-names'

export default router
